body {
  font: 16px Helvetica, Arial, sans-serif;
  color: #333;
  background-color: #fff;
  /*background-color: #EFF3F6;*/
  margin-top: 10px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased !important;
}

h2 {
  color: #333333;
  font-size: 180%;
}

hr {
  border: 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  margin-left: 15px;
}

a:link, a:visited {
  padding: 1px;
  color: #0069a8;
  text-decoration: none;
}
a:hover, a:active {
  color: #002d49;
  text-decoration: none;
}

#wrapper { 
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

#inner-wrapper {
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 0;
}

#sign_in {
  width: 100%;
  /*padding: 15px 0 15px 0;*/
  text-align: center;
  font-size: 150%;
}

#logo {
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-width: 500px;
}

/*
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  #2F5C9C

*/

#homepage_nav, #sub_nav {
  width: 75%;
  margin: 20px auto 0 auto;
}

.nav_item {
  background-color: #fff;
  margin-bottom: 20px;
  
  /* border: 1px solid #1f5394; */
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  
  -webkit-box-shadow: 0px 0px 10px 7px rgba(47,92,156,0.5);
  -moz-box-shadow: 0px 0px 10px 7px rgba(47,92,156,0.5);
  box-shadow: 0px 0px 10px 7px rgba(47,92,156,0.5);
  
}
.nav_item a {
  display: block;
  padding: 5px;
  background: transparent;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
}
.nav_item a:hover {
  color: #000;
}

.nav_item.disabled a {
  text-decoration: line-through;
  opacity: 0.3;
}

#embed_video {
  margin: 0 auto;
  margin-top: 20px;
}

@media only screen and (max-width:600px) {
  #homepage_nav, #sub_nav {
    width: 100%;
  }
}

#debug {
  display: none;
  font-size: 80%;
}

#login_form, .signup_box, #fileupload, #edit_video {
  width: 75%;
  margin: 0 auto;
  background-color: #fff;
  padding: 40px;
  margin-top: 20px;
  text-align: left;
}

#edit_video a:link {
  font-size: 90%;
}
#edit_video a:hover {
  color: #000;
}

.form_item label { 
  display: inline-block; 
  width: 80px; 
  text-align: right;
}

#fileupload label {
  text-align: left;
}

.form_remember label {
  width: 150px;	
}

#login_form input[type="text"],
#login_form input[type="password"],
#login_form input[type="submit"] {
  border: 1px solid #dedede;
    border-radius: 5px;
    box-sizing: border-box;
  width: 200px;
  padding: 2px;
  font-size: 14px;
  height: 24px;
}

#login_form input[type="text"],
#login_form input[type="password"] {
  text-indent: 5px;
}

#login_form input[type="submit"] {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  background-color: #2B5B99;
  height: 35px;	
}

#login_form a:hover {
  color: #333;
}

.form_item {
  padding: 10px;
  padding-left: 0;
}

.bar {
    height: 5px;
    background: green;
    margin-top: 5px;
}

.video_item {
  width: 100%;
  clear: left;
  text-align: left;
  border: 1px solid #eee;
  height: 90px;
  padding: 10px;
  margin-bottom: 15px;
}

.video_thumb { margin-right: 20px; float: left; }
.video_thumb img { width: 151px; height: 90px; }

.video_thumb .time {
  position: absolute;
  margin-top: 64px;
  margin-left: 113px;
  font-size: 70%;
  background: #000;
  padding: 3px;
  height: 20px;
  opacity: 0.7;
  color: #eee;
}

.user_added {
  border: 1px solid #333;
}

.follower_name {
  color: #666;
  font-size: 80%;
}

#videos_box .nav_item {
  
  margin: 0 auto;
  margin-bottom: 20px;
  
  width: 100px !important;
  
  background-color: #fff;
  
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  
  -webkit-box-shadow: 0px 0px 10px 7px rgba(47,92,156,0.2);
  -moz-box-shadow: 0px 0px 10px 7px rgba(47,92,156,0.2);
  box-shadow: 0px 0px 10px 7px rgba(47,92,156,0.2);
  
}

#videos_box .nav_item a {
  font-size: 80% !important;
}

input[type=submit] {
    border: 1px solid #0e57f4;
    color: #fff;
    background: #0e57f4;
    padding: 10px 20px;
    border-radius: 3px;
  font-size: 80%;
  margin-top: 20px;
  cursor: pointer;
}
input[type=submit]:hover {
    background: #06266b;
}

@media only screen and (max-width:800px) {
  
  #embed_code_btn {
    display: none;
  }
  
}

@media only screen and (max-width:479px) {

  .form_item label { width: 100%; text-align: left; }
  /*.follower_name { display: none; }*/
  .video_item { font-size: 90%; width: 95%; }
  #inner-wrapper { padding: 0; }
  
  .video_thumb .time {
    margin-left: 117px;
  }
  
}

#share-buttons img {
  width: 35px;
  padding: 5px;
  border: 0;
  box-shadow: 0;
  display: inline;
}

#top_section.vidfs {
  padding: 0;
  margin: 0;
  margin-top: -60px;
}

h2 {
  font-size: 120%;
}

h3 {
  font-size: 100%;
  color: #666 !important;
}

#wrapper {
  max-width: 900px;
    text-align: left;
  color: #333;
}

input[type=text] {
  padding: 10px;
}

form.login input[type="username"],
form.login input[type="password"] {
  border: 1px solid #ccc;
  border-radius: 2px;
}

#progress {
  display: none;
}

#uploading {
  opacity: 0;
}

#upload_btn:disabled {
  border: 1px solid #dadada;
  background: #dadada;
  cursor: default;
}

input[type=submit]:disabled {
  border: 1px solid #dadada;
  background: #dadada;
  cursor: default;
}

#scheduled_release {
  max-width: 307px;
}

#fileupload select {
  width: 84%;
}

#fileupload {
  width: 100%;
  padding-top: 0px;
  padding-left: 10px;
}

#datetimepicker {
  width: 150px;
}

.table td {
  /*font-size: 1.3rem;*/
}

footer {
  display: none;
  color:#999;
  text-align: center;
}

#header {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

#header strong {
  font-size: 180%;
  font-weight: normal;
  margin-right: 20px;
}

#home_box {
  margin:0 auto;
  width: 500px;
  background-color:#eee;
  padding: 15px;
  border: 1px solid #ccc;
}

#home_box ul {
  list-style-type: none;
  margin-left: 10px;
  padding-left: 0px;
}
#home_box li {
  padding: 2px;
}
#home_box ul li ul li:first-child {
  margin-top: 10px;
}

#login_box {
  margin:0 auto;
  width: 400px;
  background-color:#eee;
  padding: 15px;
  border: 1px solid #ccc;
}

#login_box span {
  display: inline-block;
  width: 100px;
}

#login_box input[type=username],
#login_box input[type=password] {
  padding: 5px;
}

.login_view .sign_out_link {
  display: none;
}

#manage-header-view ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.no-item-warning {
  margin-top: 15px;
  text-align: center;
  padding: 15px;
  background-color: #9999992d;
  color: #0069a8;
  font-weight: bold;
  border-top: 1px solid #999999a0;
}

.no-item-warning span {
  font-weight: normal;
  display: block;
  font-size: 80%;
}